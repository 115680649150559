//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';

am4core.useTheme(am4themes_animated);

export default {
  name: 'aiPerformanceChart',
  data() {
    return {
      showChart: true,
      showDuration: false,
      showInnerLoading: false,
      chart_data: [],
      isCall: false,
      mostOcuurance: {},
      mostPercentage: {},
      bestPer: {},
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: 'getDarkTheme'
    }),
  },
  methods: {
    ...mapActions({
      requestIntentStats: 'requestIntentStats',
    }),
    createConfidenceScoreChart() {
      const that = this;
      if (this.heartBeatChart) {
        this.heartBeatChart.dispose();
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark);
      }
      // Create chart instance
      const chart = am4core.create(
        this.$refs.confidenceScoreChart,
        am4charts.XYChart,
      );
      chart.scrollbarX = new am4core.Scrollbar();
      chart.doubleClickDuration = 1200;
      // Add data
      chart.data = that.chart_data;
      chart.scrollbarX = false;

      // Create axes
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'label';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.tooltip.disabled = true;
      categoryAxis.renderer.minHeight = 110;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.colorField = 'color';
      series.dataFields.valueY = 'count';
      series.dataFields.categoryX = 'label';
      series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
      series.columns.template.strokeWidth = 1;
      // series.columns.template.fill = am4core.color("#ff4444");
      series.tooltip.pointerOrientation = 'vertical';

      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.fillOpacity = 0.8;

      // on hover, make corner radiuses bigger
      const hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', (fill, target) => chart.colors.getIndex(target.dataItem.index));
      categoryAxis.sortBySeries = categoryAxis.dataFields.category;
      categoryAxis.renderer.inversed = true;
      // disable zoom
      chart.zoomOutButton.disabled = true;

      // disable Scrollbar
      chart.hideXScrollbar = true;
      chart.hideYScrollbar = true;
      // Cursor
      chart.cursor = new am4charts.XYCursor();
      // disable zooming
      chart.cursor.behavior = 'none';
      // disable cursor line
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.disabled = true;
      // Disable general cursor tootltip for range view
      valueAxis.cursorTooltipEnabled = false;
      that.heartBeatChart = chart;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Confidence_Score-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      chart.responsive.enabled = true;
    },
    updatePerformancetable(data) {
      const that = this;
      that.showTable = true;
      that.showInnerLoading = true;
      data.slotMins = 1440;
      if (that.heartBeatChart) {
        that.heartBeatChart.dispose();
      }
      this.requestIntentStats(data).then((res) => {
        if (res.headers.length > 0) {
          that.showChart = true;
          setTimeout(() => {
            that.generateChartData(res.result);
          });
        } else {
          setTimeout(() => {
            that.showInnerLoading = false;
            that.showDuration = false;
          }, 100);
        }
      }).catch((err) => {
        that.isCall = false;
        that.showChart = false;
      }).finally(() => {
        setTimeout(() => {
          that.showInnerLoading = false;
          that.isCall = false;
        }, 1000);
      });
    },
    generateChartData(data) {
      const that = this;
      const formatted_data = [];
      _.forEach(data, (intentData) => {
        delete intentData.valueList;
        const return_obj = {
          intent: intentData.intent,
          confidentScore: 0,
          occurance: 0,
        };
          for (const key in intentData) {
            if(_.isObject(intentData[key] )){
              if (key !== 'intent' && key !== 'index') {
              if (intentData[key].label == 0) {
                return_obj.confidentScore += 0;
                return_obj.occurance += 0;
              } else {
                return_obj.confidentScore += intentData[key].confidanceScore;
                return_obj.occurance += intentData[key].occurance;
              }
            }else{
              console.log('key ' ,key , intentData[key])
            }
          }
        }
        return_obj.percentage = (return_obj.confidentScore / return_obj.occurance) * 100;
        formatted_data.push(return_obj);
      });
      that.generateGroupData(formatted_data);
    },
    generateGroupData(data) {
      const that = this;

      that.mostOcuurance = _.sortBy(data, 'occurance').reverse()[0];
      const sortedPercentage = _.filter(data, (obj) => obj.percentage > 80);
      that.mostPercentage = _.sortBy(
        sortedPercentage,
        'occurance',
      ).reverse()[0];
      const group_options = [
        { label: '0 - 5 %', min: 0, max: 5 },
        { label: '5 - 10 %', min: 5, max: 10 },
        { label: '10 - 15 %', min: 10, max: 15 },
        { label: '15 - 20 %', min: 15, max: 20 },
        { label: '20 - 25 %', min: 20, max: 25 },
        { label: '25 - 30 %', min: 25, max: 30 },
        { label: '30 - 35 %', min: 30, max: 35 },
        { label: '35 - 40 %', min: 35, max: 40 },
        { label: '40 - 45 %', min: 40, max: 45 },
        { label: '45 - 50 %', min: 45, max: 50 },
        { label: '50 - 55 %', min: 50, max: 55 },
        { label: '55 - 60 %', min: 55, max: 60 },
        { label: '60 - 65 %', min: 60, max: 65 },
        { label: '65 - 70 %', min: 65, max: 70 },
        { label: '70 - 75 %', min: 70, max: 75 },
        { label: '75 - 80 %', min: 75, max: 80 },
        { label: '80 - 85 %', min: 80, max: 85 },
        { label: '85 - 90 %', min: 85, max: 90 },
        { label: '90 - 95 %', min: 90, max: 95 },
        { label: '95 - 100 %', min: 95, max: 100 },
      ];
      const final_data = _.map(group_options, (obj) => {
        const count = _.filter(data, (stats) => stats.percentage > obj.min && stats.percentage <= obj.max);
        return {
          label: obj.label,
          count: count.length,
        };
      });

      that.bestPer = _.sortBy(final_data, 'count').reverse()[0];
      that.chart_data = final_data.reverse();
      that.createConfidenceScoreChart();
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        that.isCall = false;
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
        };
        that.updatePerformancetable(data);
      }
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'onAccountUpdate',
      };
      that.isCall = false;
      that.updatePerformancetable(data);
    },
    isValid(value) {
      if (
        value === undefined
        || value === null
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    showChart(newVal) {
      const that = this;
      if (newVal == false) {
        if (that.heartBeatChart) {
          that.heartBeatChart.dispose();
        }
      }
    },
    darkTheme(){
      this.createConfidenceScoreChart()
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
    };
    that.updatePerformancetable(data);
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.heartBeatChart) {
      this.heartBeatChart.dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
